$default-color: #7BC623;
$second-color: #ff9800;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: $second-color;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;
